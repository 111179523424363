@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');


body {
  background: rgb(179,133,210);
  background: linear-gradient(90deg, rgba(179,133,210,1) 0%, rgba(255,99,99,1) 50%, rgba(255,225,127,1) 100%);
  }

.App {
  text-align: center;
} 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
